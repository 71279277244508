<template>
  <v-card class="contact-us support">
    <div class="justify-center align-center text-center mb-lg-3 title">
        <p>We are always eager to hear back from our customers.</p>
        <p>Please reach out to us with your queries and valuable feedback.</p>
    </div>
    <v-row>
        <v-col sm="12" md="3" cols="12" class="order-md-1 order-2">
            <div class="leftside-sec">
              <h2>Talk to us</h2>
              <p> Phone : <a href="tel:1800-419-0431">1800-419-0431 </a><br>Email : <a href="mailto:support@b4traders.com">support@b4traders.com</a></p>
              <h2>Address</h2>
              <p><a href="https://g.page/blubirch?share"> {{Address}}</a></p>
            </div>

        </v-col>
        <v-col sm="12" md="9" cols="12" class="order-md-2 order-1">
            <form @submit.prevent="submit" class="contact-form mt-5">
            <v-text-field
                variant="outlined"
                label="Name"
                class="required"
                v-model="name.value.value"
                :error-messages="name.errorMessage.value"
            ></v-text-field>

            <v-text-field
                variant="outlined"
                label="Phone Number"
                counter="10"
                class="required"
                maxLength="10"
                v-model="phone.value.value"
                :error-messages="phone.errorMessage.value"
            ></v-text-field>

            <v-text-field
                variant="outlined"
                type="email"
                label="Email"
                class="required"
                v-model="email.value.value"
                :error-messages="email.errorMessage.value"
            ></v-text-field>

            <v-select
                label="Subject"
                :items="subjects"
                variant="outlined"
                class="required"
                required
                v-model="selectedItems"
                :error-messages="subject.errorMessage.value"
            ></v-select>

            <v-textarea
                label="Remarks"
                variant="outlined"
                class="required"
                v-model="remarks.value.value"
                :error-messages="remarks.errorMessage.value"
            ></v-textarea>

            <v-card-actions class="justify-center">
                <v-btn
                variant="elevated"
                class="bg-theme-primary"
                type="submit"
                size="large"
                >
                Submit
                </v-btn>
            </v-card-actions>
            </form>
        </v-col>

    </v-row>
  </v-card>
</template>

<script>
  import app from './index.js';
  export default app;
</script>