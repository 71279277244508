<template>
  <section class="registerbuyer mt-5">
    <v-container>
      <v-col lg="6" class="ms-md-auto">
        <h3 class="heading text-white mb-10">
          Register and become part of the largest community of buyers
        </h3>
        <v-btn
          size="large"
          color="blue"
          variant="outlined"
          class="bg-white"
          @click="handleRedirect()"
        >
          REGISTER AS A BUYER
        </v-btn>
      </v-col>
    </v-container>
  </section>
</template>

<script setup>
  import { useRouter } from "vue-router";

  const router = useRouter();
  const handleRedirect = () => {
    router.push({ name: "SignUp" });
  }
</script>
