import RegisterBanner from "../../components/RegisterBanner/index.vue";
import KeyPartners from "../../components/KeyPartners/index.vue";
import Featured from "../../components/Featured/index.vue";
import step1 from "@/../assets/images/step1.png"
import step2 from "@/../assets/images/step2.png"
import step3 from "@/../assets/images/step3.png"
import step4 from "@/../assets/images/step4.png"
import step5 from "@/../assets/images/step5.png"
import group from "@/../assets/images/group.svg"


export default {
  components: {
    RegisterBanner,
    KeyPartners,
    Featured,
  },
  setup() {
    return {
      step1,
      step2,
      step3,
      step4,
      step5,
      group
    }
  }
};