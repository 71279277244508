<template>
  <section class="key-partners">
    <v-container>
      <v-row>
        <v-col xl="12" md="12" sm="12">
          <div class="py-lg-8">
            <h1 class="heading text-center">Key Partners</h1>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col xl="12" md="12" sm="12">
          <div class="partners-section">
            <div>
              <v-carousel
                class="h-auto"
                hide-delimiters
                :show-arrows="false"
                cycle
              >
                <v-carousel-item v-for="k in 6" :key="n">
                  <v-row class="flex-nowrap">
                    <div class="carousel-v_col ma-2" v-for="n in 8" :key="n">
                      <div class="pa-2 ma-2 rounded-lg"
                      >
                        <div>
                          <div class="d-flex flex-column">
                            <v-scale-transition>
                              <div>
                                <v-row class="mb-5 py-4">
                                  <v-col
                                    xl="8"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    class="pa-2"
                                  >
                                    <v-img
                                      :src="getImage(k, n, 0)"
                                      height="96"
                                      class="brand-img"
                                      contain
                                    ></v-img>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col
                                    xl="8"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    class="pa-2"
                                  >
                                    <v-img
                                      :src="getImage(k, n, 2)"
                                      height="96"
                                      class="brand-img"
                                      contain
                                    ></v-img>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-scale-transition>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup>
    import img1 from "@/../assets/images/1.png"
    import img2 from "@/../assets/images/2.png"
    import img3 from "@/../assets/images/3.png"
    import img4 from "@/../assets/images/4.png"
    import img5 from "@/../assets/images/5.png"
    import img6 from "@/../assets/images/6.png"
    import img7 from "@/../assets/images/7.png"
    import img8 from "@/../assets/images/8.png"
    import img9 from "@/../assets/images/9.png"
    import img10 from "@/../assets/images/10.png"
    import img11 from "@/../assets/images/11.png"
    import img12 from "@/../assets/images/12.png"
    import img13 from "@/../assets/images/13.png"

    const getImage = (sliderIndex, imgIndex, colIndex) => {
      let imgVal = sliderIndex * (imgIndex + colIndex)
      const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13]
      if(!images[imgVal]) {
        imgVal = imgVal % 13;
      }
      return images[imgVal]
    }

</script>